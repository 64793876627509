var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wxSecurity" }, [
    _c("div", { staticClass: "wxSecurityTitle" }, [_vm._v("安全管理驾驶舱")]),
    _c("div", { staticClass: "wxVideoTitle" }, [
      _c(
        "div",
        {
          staticClass: "wxVideoTitleInput",
          on: { click: _vm.showWxVideoTitleItemFunction },
        },
        [
          _c("div", { staticClass: "dian1" }, [
            _vm._v(_vm._s(_vm.wxVideoTitleInputName)),
          ]),
          _c("div", { staticClass: "wxVideoTitleInputIcon" }, [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showWxVideoTitleItem,
                  expression: "!showWxVideoTitleItem",
                },
              ],
              staticClass: "el-icon-caret-bottom",
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWxVideoTitleItem,
                  expression: "showWxVideoTitleItem",
                },
              ],
              staticClass: "el-icon-caret-top",
            }),
          ]),
        ]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showWxVideoTitleItem,
            expression: "showWxVideoTitleItem",
          },
        ],
        staticClass: "wxVideoTitleItem",
      },
      [
        _c("div", { staticClass: "itemTextLiBox" }, [
          _c(
            "div",
            { staticClass: "itemTextLi" },
            _vm._l(_vm.nowUserDeptList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "itemText",
                  class: { active: _vm.wxVideoTitleInputName == item.title },
                  on: {
                    click: function ($event) {
                      return _vm.ProjectSwitching(item)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "wxSecurityContent" }, [
      _c("div", { staticClass: "wxSecurityContentItem" }, [
        _vm._m(0),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "table-main-blueLine" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.AQSClist.length != "0",
                    expression: "AQSClist.length != '0'",
                  },
                ],
                staticClass: "gunDong",
                attrs: { id: "AQSCsetInterval" },
              },
              _vm._l(_vm.AQSClist, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { height: "4.6vh" } },
                  [
                    _vm._m(1, true),
                    _c(
                      "span",
                      {
                        staticClass: "dian1 gunDongText",
                        staticStyle: { width: "40%" },
                      },
                      [_vm._v(_vm._s(item.orgName))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "gunDongText",
                        staticStyle: { width: "20%", "text-align": "right" },
                      },
                      [
                        _c("span", { staticClass: "skyblueFont LED" }, [
                          _vm._v(_vm._s(item.value1) + " "),
                        ]),
                        _vm._v(" 天\n              "),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "gunDongText",
                        staticStyle: { width: "30%", "text-align": "right" },
                      },
                      [
                        _c("span", { staticClass: "skyblueFont LED" }, [
                          _vm._v(_vm._s(item.value) + " "),
                        ]),
                        _vm._v(" 工时\n              "),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.AQSClist.length == "0",
                    expression: "AQSClist.length == '0'",
                  },
                ],
                staticClass: "Nodataavailableatthemoment",
              },
              [_c("img", { attrs: { src: _vm.emptyUrl, alt: "" } })]
            ),
          ]),
        ]),
      ]),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c("div", { staticClass: "wxSecurityContentItem" }, [
        _vm._m(5),
        _c(
          "div",
          { staticClass: "main" },
          [
            _c(
              "el-carousel",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.BQJDHList.length > "0",
                    expression: "BQJDHList.length > '0'",
                  },
                ],
                attrs: {
                  height: "24vh",
                  interval: "5000",
                  "indicator-position": "none",
                  arrow: "never",
                },
              },
              _vm._l(_vm.BQJDHList, function (item, index) {
                return _c(
                  "el-carousel-item",
                  { key: index },
                  _vm._l(item.data, function (items, indexs) {
                    return _c("div", { key: indexs, staticClass: "elItem" }, [
                      _c("div", { staticClass: "elItemImageBox" }, [
                        _c("img", {
                          staticClass: "elItemImage",
                          attrs: { src: items.imgSrc, alt: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "LBtxt" }, [
                        _c("div", [
                          _c(
                            "span",
                            {
                              staticClass: "LBtitle",
                              staticStyle: {
                                color: "#00f0ff",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(items.title) +
                                  "\n                  "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c(
                            "span",
                            {
                              staticClass: "dian2 LBcontet",
                              staticStyle: {
                                color: "#fff",
                                "font-weight": "500",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(items.meetingContents) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "LBtime",
                            staticStyle: {
                              color: "#fff",
                              "font-weight": "500",
                            },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(items.meetingTime) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                )
              }),
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.BQJDHList.length == "0",
                    expression: "BQJDHList.length == '0'",
                  },
                ],
                staticClass: "Nodataavailableatthemoment",
              },
              [_c("img", { attrs: { src: _vm.emptyUrl, alt: "" } })]
            ),
          ],
          1
        ),
      ]),
      _vm._m(6),
      _vm._m(7),
      _c("div", { staticClass: "wxSecurityContentItem" }, [
        _vm._m(8),
        _c("div", { staticClass: "main" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.XMDTlist.length != "0",
                  expression: "XMDTlist.length != '0'",
                },
              ],
              staticClass: "pojectNews",
              attrs: { id: "XMDTsetInterval" },
            },
            _vm._l(_vm.XMDTlist, function (item) {
              return _c(
                "div",
                {
                  key: item.ID,
                  staticStyle: { height: "3.5vh", "font-size": "0.7813vw" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/visualization/securityManagement/dongtai.png"),
                      alt: "",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dian1",
                      staticStyle: {
                        width: "30%",
                        "margin-left": "1.5vh",
                        color: "#fff",
                        "font-weight": "500",
                        "font-size": "15px",
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.value) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dian1",
                      staticStyle: {
                        width: "30%",
                        "margin-left": "1.5vh",
                        color: "#fff",
                        "font-weight": "500",
                        "font-size": "15px",
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.value1) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dian1",
                      staticStyle: {
                        width: "30%",
                        "margin-left": "1.5vh",
                        color: "#fff",
                        "font-weight": "500",
                        "font-size": "15px",
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.value2) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.XMDTlist.length == "0",
                  expression: "XMDTlist.length == '0'",
                },
              ],
              staticClass: "Nodataavailableatthemoment",
            },
            [_c("img", { attrs: { src: _vm.emptyUrl, alt: "" } })]
          ),
        ]),
      ]),
      _vm._m(9),
      _vm._m(10),
      _vm._m(11),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
      _vm._v("\n        安全生产工时\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "gunDongImage",
        staticStyle: {
          width: "7%",
          display: "flex",
          "align-items": "center",
          "margin-right": "5px",
        },
      },
      [
        _c("img", {
          attrs: {
            src: require("../../../assets/visualization/securityManagement/dun.png"),
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        现场人员分布情况\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "XCRYTJecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        安全隐患统计\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "AQYHTJecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        AI智能分析\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "AIecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
      _vm._v("\n        班前会及交底\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        危险源、环境因素辨识\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "WXYecharets" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        今日安全检查统计\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "JRAQXCecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
      _vm._v("\n        预警管理\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        应用情况分析\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "YYQKFXecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        今日安全检查统计\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "AQPXXTecharts" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wxSecurityContentItem" }, [
      _c("div", { staticClass: "wxSecurityContentItemTitle" }, [
        _c("div", { staticClass: "wxSecurityContentItemTitle_icon" }),
        _vm._v("\n        安全奖罚\n      "),
      ]),
      _c("div", { staticClass: "main", attrs: { id: "AQJFecharts" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }