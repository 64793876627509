
<template>
  <div class="wxSecurity">
    <div class="wxSecurityTitle">安全管理驾驶舱</div>
    <div class="wxVideoTitle">
      <div class="wxVideoTitleInput" @click="showWxVideoTitleItemFunction">
        <div class="dian1">{{ wxVideoTitleInputName }}</div>
        <div class="wxVideoTitleInputIcon">
          <i v-show="!showWxVideoTitleItem" class="el-icon-caret-bottom"></i>
          <i v-show="showWxVideoTitleItem" class="el-icon-caret-top"></i>
        </div>
      </div>
    </div>
    <div class="wxVideoTitleItem" v-show="showWxVideoTitleItem">
      <div class="itemTextLiBox">
        <div class="itemTextLi">
          <div
            class="itemText"
            v-for="(item, index) in nowUserDeptList"
            :key="index"
            :class="{ active: wxVideoTitleInputName == item.title }"
            @click="ProjectSwitching(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="wxSecurityContent">
      <!-- 安全生产工时 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          安全生产工时
        </div>
        <div class="main">
          <div class="table-main-blueLine">
            <div
              class="gunDong"
              id="AQSCsetInterval"
              v-show="AQSClist.length != '0'"
            >
              <div
                v-for="(item, index) in AQSClist"
                :key="index"
                style="height: 4.6vh"
              >
                <span
                  class="gunDongImage"
                  style="
                    width: 7%;
                    display: flex;
                    align-items: center;
                    margin-right: 5px;
                  "
                >
                  <img
                    src="../../../assets/visualization/securityManagement/dun.png"
                    alt=""
                  />
                </span>
                <span style="width: 40%" class="dian1 gunDongText">{{
                  item.orgName
                }}</span>
                <span style="width: 20%; text-align: right" class="gunDongText">
                  <span class="skyblueFont LED">{{ item.value1 }} </span> 天
                </span>
                <span style="width: 30%; text-align: right" class="gunDongText">
                  <span class="skyblueFont LED">{{ item.value }} </span> 工时
                </span>
              </div>
            </div>
            <div
              class="Nodataavailableatthemoment"
              v-show="AQSClist.length == '0'"
            >
              <img :src="emptyUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 现场人员分布情况 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          现场人员分布情况
        </div>
        <div class="main" id="XCRYTJecharts"></div>
      </div>
      <!-- 安全隐患统计 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          安全隐患统计
        </div>
        <div class="main" id="AQYHTJecharts"></div>
      </div>
        <!-- AI智能分析 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          AI智能分析
        </div>
        <div class="main" id="AIecharts"></div>
      </div>
      <!-- 班前会及交底 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          班前会及交底
        </div>
        <div class="main">
          <!-- 循环内部循环 -->
          <el-carousel
            height="24vh"
            interval="5000"
            indicator-position="none"
            arrow="never"
            v-show="BQJDHList.length > '0'"
          >
            <el-carousel-item v-for="(item, index) in BQJDHList" :key="index">
              <div
                class="elItem"
                v-for="(items, indexs) in item.data"
                :key="indexs"
              >
                <!-- ../../../assets/visualization/securityManagement/noImg.png -->
                <div class="elItemImageBox">
                  <img class="elItemImage" :src="items.imgSrc" alt="" />
                </div>
                <div class="LBtxt">
                  <div>
                    <span
                      class="LBtitle"
                      style="color: #00f0ff; font-weight: 500"
                    >
                      {{ items.title }}
                    </span>
                    <br />
                    <span
                      class="dian2 LBcontet"
                      style="color: #fff; font-weight: 500"
                    >
                      {{ items.meetingContents }}
                    </span>
                  </div>
                  <div class="LBtime" style="color: #fff; font-weight: 500">
                    {{ items.meetingTime }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div
            class="Nodataavailableatthemoment"
            v-show="BQJDHList.length == '0'"
          >
            <img :src="emptyUrl" alt="" />
          </div>
        </div>
      </div>
      <!-- 危险源、环境因素辨识 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          危险源、环境因素辨识
        </div>
        <div class="main" id="WXYecharets"></div>
      </div>
      <!-- 今日安全检查统计 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          今日安全检查统计
        </div>
        <div class="main" id="JRAQXCecharts"></div>
      </div>
      <!--  预警管理 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          预警管理
        </div>
        <div class="main">
          <div
            class="pojectNews"
            id="XMDTsetInterval"
            v-show="XMDTlist.length != '0'"
          >
            <div
              v-for="item in XMDTlist"
              :key="item.ID"
              style="height: 3.5vh; font-size: 0.7813vw"
            >
              <img
                src="../../../assets/visualization/securityManagement/dongtai.png"
                alt=""
              />
              <span
                style="
                  width: 30%;
                  margin-left: 1.5vh;
                  color: #fff;
                  font-weight: 500;
                  font-size: 15px;
                "
                class="dian1"
              >
                {{ item.value }}
              </span>
              <span
                style="
                  width: 30%;
                  margin-left: 1.5vh;
                  color: #fff;
                  font-weight: 500;
                  font-size: 15px;
                "
                class="dian1"
              >
                {{ item.value1 }}
              </span>
              <span
                style="
                  width: 30%;
                  margin-left: 1.5vh;
                  color: #fff;
                  font-weight: 500;
                  font-size: 15px;
                "
                class="dian1"
              >
                {{ item.value2 }}
              </span>
            </div>
          </div>
          <div
            class="Nodataavailableatthemoment"
            v-show="XMDTlist.length == '0'"
          >
            <img :src="emptyUrl" alt="" />
          </div>
        </div>
      </div>
      <!-- 应用情况分析 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          应用情况分析
        </div>
        <div class="main" id="YYQKFXecharts"></div>
      </div>
      <!-- 安全培训系统 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          今日安全检查统计
        </div>
        <div class="main" id="AQPXXTecharts"></div>
      </div>
      <!-- 安全奖罚 -->
      <div class="wxSecurityContentItem">
        <div class="wxSecurityContentItemTitle">
          <div class="wxSecurityContentItemTitle_icon"></div>
          安全奖罚
        </div>
        <div class="main" id="AQJFecharts"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  visualization,
  dbtbeforeclass,
  securityinspection,
  getProjectCountByApplied,
  dbtmonitoraccountStatSummary
} from "../../../api/visualization/index";
import { nowUserDept } from "@/api/user";
export default {
  components: {},
  data() {
    return {
      wxVideoTitleInputName: "中材国际",
      AQSClist: [],
      emptyUrl: require("../../../assets/visualization/noData.png"),
      nowUserDeptList: [],
      deptId: "1722789594114084866",
      nowYear: new Date().getFullYear(),
      BQJDHList: [],
      companyId: "-1",
      timerId: null,
      showWxVideoTitleItem: false,
      XMDTlist: [

      ],
    };
  },
  created() {
    let that = this;
    if (window.location.hostname == "123.60.160.227") {
      that.deptId = "1782377952097869826";
    } else if (window.location.hostname == "39.106.228.40") {
      that.deptId = "1782377952097869826";
    } else {
      that.deptId = "1722789594114084866";
    }
  },
  mounted() {
    let that = this;
    nowUserDept().then((res) => {
      if (res.data.code === 200) {
        that.nowUserDeptList = res.data.data;
        console.log("项目数据源", that.nowUserDeptList);
      }
    });
    // that.openTimer("AQSCsetInterval");
    this.Safeproductionworkinghours();
    this.XCRYTJecharts();
    this.AQYHTJecharts();
    this.bqhFunction();
    this.AIecharts()
    this.WXYecharets();
    this.XMDTlistFunction();
    this.JRAQXCecharts();
    this.YYQKFXecharts();
    this.AQPXXTecharts();
    this.AQJFecharts();
  },
  beforeDestroy() {},
  // 计算属性
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    showWxVideoTitleItemFunction() {
      this.showWxVideoTitleItem = !this.showWxVideoTitleItem;
    },
    ProjectSwitching(item) {
      console.log("切换项目", item);
      this.wxVideoTitleInputName = item.title;
      this.deptId = item.id;
      this.ConditionalQuery();
      this.showWxVideoTitleItem = false;
    },
    ConditionalQuery() {
      this.Safeproductionworkinghours();
      this.XCRYTJecharts();
      this.AQYHTJecharts();
      this.bqhFunction();
      this.AIecharts();
      this.WXYecharets();
      this.XMDTlistFunction();
      this.YYQKFXecharts();
      this.JRAQXCecharts();
      this.AQPXXTecharts();
      this.AQJFecharts();
    },
    // 安全生产工时
    Safeproductionworkinghours() {
      let that = this;
      let arr = [];
      let num;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00142_Y&indexCodes=ZB00143_Y`;
      visualization(params).then((res) => {
        console.log("安全生产工时", res);
        if (res.data.data.ZB00142_Y.length > 0) {
          res.data.data.ZB00142_Y.forEach((item, index) => {
            if (item.value) {
              num = item.value / 8;
            } else {
              num = 0;
            }
            arr.push({
              orgName: item.orgName,
              value1: num,
              value: item.value,
            });
          });
        }
        that.AQSClist = arr;
        if (this.AQSClist.length > 5) {
          this.startScroll();
        } else {
          this.stopScroll();
          // 处理数据长度小于等于5的情况
          this.updateDOM(this.AQSClist);
        }
      });
    },
    startScroll() {
      this.stopScroll(); // 确保停止旧的滚动
      const domElement = document.querySelector("#AQSCsetInterval");
      if (!domElement) return;

      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * 4.6}vh`;

        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.AQSClist.shift();
            this.AQSClist.push(firstChild);
            this.timerId = setTimeout(everyJump, 3000);
          },
          { once: true }
        );
      };
      this.timerId = setTimeout(everyJump, 1000);
    },
    stopScroll() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      const domElement = document.querySelector("#AQSCsetInterval");
      if (domElement) {
        domElement.style.transition = "";
        domElement.style.marginTop = "0px";
        domElement.removeEventListener(
          "transitionend",
          this.transitionEndHandler
        );
      }
    },
    updateDOM(list) {
      const domElement = document.querySelector("#AQSCsetInterval");
      if (!domElement) return;
      domElement.style.transition = "";
      domElement.style.marginTop = "0px";
      // 更新DOM内容
    },

    // ai智能分析
    AIecharts() {
      let that = this;
      var m2R2Data = [];
      let category = [];
      let barData = [];
      var dom = document.getElementById("AIecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let arr = [];
      let num = 0;
      let option;
      let params = {
        year: "",
        month: "",
        orgId: this.deptId,
      };
      dbtmonitoraccountStatSummary(params).then((res) => {
        arr = res.data.data.itemList;
        if (arr.length > 0) {
          arr.forEach((item) => {
            category.push({
              value: item.count,
              legendname: item.typeName,
              name: item.typeName + "  " + item.count,
            });
            num += item.count;
          });
          // 饼图
          option = {
            title: [
              {
                text: "合计",
                subtext: num + "个",
                textStyle: {
                  fontSize: 14,
                  color: "#fff",
                },
                subtextStyle: {
                  fontSize: 14,
                  color: "#1191ff",
                  fontWeight: "bold",
                },
                textAlign: "center",
                x: "28.5%",
                y: "43%",
              },
            ],
            tooltip: {
              trigger: "item",
              formatter: function (parms) {
                var str =
                  parms.seriesName +
                  "</br>" +
                  parms.marker +
                  "" +
                  parms.data.legendname +
                  "</br>" +
                  "数量：" +
                  parms.data.value +
                  "</br>" +
                  "占比：" +
                  parms.percent +
                  "%";
                return str;
              },
            },
            legend: {
              type: "scroll",
              orient: "vertical",
              left: "63.5%",
              align: "left",
              top: "middle",
              textStyle: {
                color: "#fff",
              },
              height: 180,
              pageTextStyle: {
                color: "#fff",
              },
              pageIconColor: "#1b9aee",
            },
            series: [
              {
                name: "违章类型",
                type: "pie",
                center: ["30%", "50%"],
                radius: ["45%", "75%"],
                clockwise: false, //饼图的扇区是否是顺时针排布
                avoidLabelOverlap: false,
                label: {
                  normal: {
                    show: false,
                    position: "outter",
                    formatter: function (parms) {
                      return parms.data.typeName;
                    },
                    color: "#fff",
                  },
                },
                labelLine: {
                  normal: {
                    length: 5,
                    length2: 3,
                    smooth: true,
                  },
                },
                data: category,
              },
            ],
          };

          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },


    // 预警管理
    XMDTlistFunction() {
      let that = this;
      let arr = [];
      let str;
      that.XMDTlist = [];
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00152_Y`;
      visualization(params)
        .then((res) => {
          if (res.data.data.ZB00152_Y.length > 0) {
            res.data.data.ZB00152_Y.forEach((item, index) => {
              str = item.value2.split(",");
              arr.push({
                orgName: item.orgName,
                value: str[0],
                value1: str[1],
                value2: str[2],
              });
            });
          }

          that.XMDTlist = arr;
          if (this.XMDTlist.length > 5) {
            this.XMDTlistStartScroll();
          } else {
            this.XMDTlistStopScroll();
            // 处理数据长度小于等于5的情况
            this.XMDTlistUpdateDOM(this.XMDTlist);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
    // 预警管理数据格式处理
    XMDTlistStartScroll() {
      this.XMDTlistStopScroll(); // 确保停止旧的滚动
      const domElement = document.querySelector("#XMDTsetInterval");
      if (!domElement) return;

      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * 4.6}vh`;

        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.XMDTlist.shift();
            this.XMDTlist.push(firstChild);
            this.XMDTlisttimerId = setTimeout(everyJump, 3000);
          },
          { once: true }
        );
      };
      this.XMDTlisttimerId = setTimeout(everyJump, 1000);
    },
    // 预警管理数据格式处理
    XMDTlistStopScroll() {
      if (this.XMDTlisttimerId) {
        clearTimeout(this.XMDTlisttimerId);
        this.XMDTlisttimerId = null;
      }
      const domElement = document.querySelector("#XMDTsetInterval");
      if (domElement) {
        domElement.style.transition = "";
        domElement.style.marginTop = "0px";
        domElement.removeEventListener(
          "transitionend",
          this.transitionEndHandler
        );
      }
    },
    XMDTlistUpdateDOM(list) {
      const domElement = document.querySelector("#XMDTsetInterval");
      if (!domElement) return;
      domElement.style.transition = "";
      domElement.style.marginTop = "0px";
      // 更新DOM内容
    },

    // 应用情况分析
    YYQKFXecharts() {
      let that = this;
      let dom = document.getElementById("YYQKFXecharts");
      that.$echarts.init(dom).dispose();
      let myChart = that.$echarts.init(dom);
      let dataX = [];
      let data1 = [];
      let arr = [];
      let option;
      let params = `createDept=${that.deptId}`;
      getProjectCountByApplied(params)
        .then((res) => {
          arr = res.data.data;
          if (arr.length > 0) {
            arr.forEach((item) => {
              dataX.push(item.label);
              data1.push(item.value);
            });
            option = {
              legend: {
                data: ["近一周应用次数"],
                textStyle: {
                  color: "#FFF",
                },
                right: "0%",
                icon: "circle",
                itemWidth: 10, // 设置宽度
                itemHeight: 10, // 设置高度
              },
              grid: {
                top: "20%",
                bottom: "15%",
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: [],
                axisTick: {
                  show: false, //外刻度
                  inside: true, //内刻度
                  length: 5,
                },
                axisLabel: {
                  textStyle: {
                    color: "#FFFFFF", //更改坐标轴文字颜色
                    fontSize: 12, //更改坐标轴文字大小
                  },

                  formatter: function (value) {
                    var res = value;
                    if (res.length > 4) {
                      res = res.substring(0, 3) + "..";
                    }
                    return res;
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#002490", //更改坐标轴颜色
                  },
                },
                splitLine: {
                  //去除网格线
                  show: false,
                },
              },
              yAxis: {
                type: "value",
                splitLine: {
                  //去除网格线
                  show: true,
                  lineStyle: {
                    color: "#4E548B", //更改刻度尺颜色
                    type: "dashed", //'dotted'虚线 'solid'实线
                  },
                },
                // splitArea: { show: false },//去除网格区域
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "#FFFFFF", //更改坐标轴文字颜色
                    fontSize: 10, //更改坐标轴文字大小
                  },
                },
              },

              series: [
                {
                  name: "近一周应用次数",
                  data: [],
                  type: "line",
                  areaStyle: {},
                  lineStyle: {},
                  symbol: "circle",
                  symbolSize: 5, //指示点大小
                  smooth: 0.5, //弯曲程度
                  areaStyle: {
                    // 折现下是否填充
                    color: {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(0,81,150,1)", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(0,81,150,0.2)", // 100% 处的颜色
                        },
                      ],
                      global: false,
                    },
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      formatter: "{c}",
                      color: "#ffffff",
                      fontSize: 12,
                    },
                  },
                },
              ],
            };
            var Interval = null;
            clearInterval(Interval);
            InItOpen();
            Interval = setInterval(InItOpen, 3000);
            function InItOpen() {
              if (data1.length > 5) {
                option.xAxis.data = that.baseData(option, dataX, 5);
                option.series[0].data = that.baseData(option, data1, 5);
              } else {
                option.xAxis.data = dataX;
                option.series[0].data = data1;
              }
              myChart.setOption(option);
            }
            window.addEventListener("resize", myChart.resize());
            option && myChart.setOption(option);
          } else {
            option = this.echartsNoData();
            option && myChart.setOption(option);
          }
        })
        .catch((error) => {
          console.error("请求失败:", error);
        });
    },
    //现场人员将统计
    XCRYTJecharts() {
      let that = this;
      var dom = document.getElementById("XCRYTJecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let data1 = [];
      let data2 = [];
      let data3 = [];
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00144_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00144_Y.length > 0) {
          res.data.data.ZB00144_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
          });
          option = {
            legend: {
              textStyle: {
                color: "#fff",
              },
              right: "0",
            },
            grid: {
              left: "5%",
              right: "5%",
              bottom: "10%",
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B9C8DB", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            xAxis: {
              type: "category",
              data: [],
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 4) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            series: [
              {
                name: "内部人员",
                data: [],
                // stack: 'total',
                type: "bar",
                barWidth: "15",
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#14d8f9",
                    },
                    {
                      offset: 1,
                      color: "#0285fc",
                    },
                  ]),
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                  color: "#fff",
                  // position: 'insideLeft',
                  // offset: [fontSize(2.5), 0],
                },
              },
              {
                name: "外部人员",
                data: [],
                // stack: 'total',
                type: "bar",
                barWidth: "15",
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    {
                      offset: 0,
                      color: "#ffb708",
                    },
                    {
                      offset: 1,
                      color: "#fba980",
                    },
                  ]),
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: "{c}",
                  color: "#fff",
                },
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 安全隐患统计
    AQYHTJecharts() {
      let that = this;
      var dataX = [];
      var data1 = [];
      var data2 = [];
      var data3 = [];
      var dom = document.getElementById("AQYHTJecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00147_Y`;
      visualization(params).then((res) => {
        let arr;
        if (res.data.data.ZB00147_Y.length > 0) {
          res.data.data.ZB00147_Y.forEach((item) => {
            dataX.push(item.orgName);
            data3.push(item.value);
            arr = item.value2.split(",");
            data1.push(arr[0]);
            data2.push(arr[1]);
          });
          option = {
            legend: {
              orient: "horizontal",
              // icon: 'roundRect',
              top: "5%",
              right: "6%",
              data: ["隐患总数", "已整改", "超期未整改"],
              itemWidth: 9, // 设置图例图形的宽
              itemHeight: 9,
              textStyle: {
                //图例文字的样式
                color: "#fff",
                fontSize: 11,
              },
            },
            grid: {
              left: "3%",
              right: "8%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: [],
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
              axisLabel: {
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#002490", //更改坐标轴颜色
                },
              },
            },

            yAxis: {
              show: false,
              type: "value",
            },
            series: [
              {
                name: "隐患总数",
                type: "bar",
                barWidth: "15",
                // stack: 'Total',
                data: [],
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                          // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                          offset: 0,
                          color: "#4774db",
                        },
                        {
                          offset: 1,
                          color: "#4774db",
                        },
                      ]
                    ),
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
                // barGap: "-100%"
              },

              {
                name: "已整改",
                smooth: true,
                type: "bar",
                barWidth: "15",
                // stack: 'Total',
                data: [],
                itemStyle: {
                  color: "#9fe080",
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
              },
              {
                name: "超期未整改",
                smooth: true,
                type: "bar",
                barWidth: "15",
                // stack: 'Total',
                data: [],
                itemStyle: {
                  color: "#ec7634",
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#fff",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, dataX, 5);
              option.series[0].data = that.baseData(option, data3, 5);
              option.series[1].data = that.baseData(option, data2, 5);
              option.series[2].data = that.baseData(option, data1, 5);
            } else {
              option.xAxis.data = dataX;
              option.series[0].data = data3;
              option.series[1].data = data2;
              option.series[2].data = data1;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 班前会及交底
    bqhFunction() {
      let that = this;
      let data = {
        current: 1,
        size: 999,
        companyId: this.companyId,
        createDept: this.deptId,
        draft: 0,
      };
      dbtbeforeclass(data).then((res) => {
        console.log(res.data.data.records);
        res.data.data.records.forEach((item) => {
          let urs = JSON.parse(item.filesUser);
          // item.filesUser = urs[0].link;
          item.imgSrc = urs[0]?.link;
          item.meetingTime = item.meetingTime.split(" ")[0];
        });
        that.BQJDHList = that.transformData(res.data.data.records);
      });
    },
    // 班前会数据格式处理
    transformData(originalData) {
      let transformedData = [];
      let currentPage = { data: [] };
      for (let i = 0; i < originalData.length; i++) {
        if (i % 2 === 0) {
          if (currentPage.data.length > 0) {
            transformedData.push(currentPage);
            currentPage = { data: [] };
          }
          currentPage.data.push(originalData[i]);
        } else {
          currentPage.data.push(originalData[i]);
        }
      }
      if (currentPage.data.length > 0) {
        transformedData.push(currentPage);
      }
      return transformedData;
    },
    // 危险源、环境因素辨识
    WXYecharets() {
      let that = this;
      let category = [];
      let barData = [];
      let dom = document.getElementById("WXYecharets");
      this.$echarts.init(dom).dispose();
      let myChart = this.$echarts.init(dom);
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00146_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00146_Y.length > 0) {
          res.data.data.ZB00146_Y.forEach((item) => {
            category.push(item.orgName);
            barData.push(item.value);
          });
          option = {
            grid: {
              top: "8%",
              left: "6%",
              right: "5%",
              bottom: "-2%",
              containLabel: true,
            },
            xAxis: {
              max: 150,
              type: "value",
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                //外刻度
                show: false,
              },
            },
            yAxis: [
              {
                type: "category",
                splitLine: {
                  //坐标线
                  show: false,
                },
                axisLabel: {
                  //y内容
                  show: false,
                },
                axisLine: {
                  //y轴线
                  show: false,
                },
                axisTick: {
                  //外刻度
                  show: false,
                },
                data: [],
              },
              {
                type: "category",
                splitLine: {
                  //坐标线
                  show: false,
                },
                axisLabel: {
                  //y内容
                  show: true,
                  formatter: function (v) {
                    return "{a|" + v + "项}";
                  },
                  rich: {
                    a: {
                      fontSize: "16px",
                      color: "#1f6acf",
                      //width: 290,
                      align: "right",
                    },
                  },
                },
                axisLine: {
                  //y轴线
                  show: false,
                },
                axisTick: {
                  //外刻度
                  show: false,
                },
                data: [],
              },
            ],
            series: [
              {
                //name: '2011年',
                type: "bar",
                silent: true,
                barWidth: 10,
                barGap: "-100%", // Make series be overlap
                data: [],
                itemStyle: {
                  normal: {
                    barBorderRadius: 7,
                    color: new this.$echarts.graphic.LinearGradient(
                      1,
                      0,
                      0,
                      0,
                      [
                        { offset: 0, color: "#1f6acf" },
                        { offset: 1, color: "#5adff8" },
                      ]
                    ),
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "insideLeft",
                    formatter: "{b}\n\n\n",
                    textStyle: {
                      color: "#5adff8",
                      fontSize: "16px", //文字的字体大小
                    },
                  },
                },
                z: 1,
              },
              {
                type: "bar",
                silent: true,
                barWidth: 10,
                data: [],
                itemStyle: {
                  normal: {
                    barBorderRadius: 5,
                    color: "rgba(179,226,255, 0.3)",
                  },
                },
                z: 0,
              },
            ],
          };

          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            var detaMax = 0;
            var dataJson = [];
            var detaMaxData = barData;
            for (var i = 0; i < detaMaxData.length; i++) {
              if (Number(detaMaxData[i]) > detaMax) {
                detaMax = Number(detaMaxData[i]);
              }
            }
            option.xAxis.max = detaMax;
            if (barData.length > 4) {
              option.yAxis[0].data = that.baseData(option, category, 4);
              option.yAxis[1].data = that.baseData(option, barData, 4);
              option.series[0].data = option.yAxis[1].data;
            } else {
              option.yAxis[0].data = category;
              option.yAxis[1].data = barData;
              option.series[0].data = barData;
            }
            for (var i = 0; i < barData.length; i++) {
              //如果最大值为0，则给数据默认赋值为1
              var MaxBarData = Math.max.apply(null, barData);
              if (MaxBarData == "0") {
                dataJson.push("1");
              } else {
                if (barData[i] == "0") {
                  dataJson.push(detaMax);
                } else {
                  dataJson.push("0");
                }
              }
            }
            option.series[1].data = dataJson;
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 今日安全巡检统计
    JRAQXCecharts() {
      let that = this;
      var dom = document.getElementById("JRAQXCecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let data = [];
      let num = 0;
      let option;
      let params = {
        createDept: this.deptId,
      };
      securityinspection(params).then((res) => {
        console.log("今日安全检查统计", res);
        if (res.data.data.length > 0) {
          res.data.data.forEach((item) => {
            num += item.count;
            data.push(item.count);
          });
          option = {
            title: {
              text: num,
              subtext: "巡查次数",
              left: "49%",
              top: "43%",
              textAlign: "center",

              textStyle: {
                color: "#00D89A",
                fontSize: "15px",
              },
              subtextStyle: {
                color: "#FFF",
                fontSize: "15px",
              },
            },
            series: [
              {
                name: "Nightingale Chart",
                type: "pie",
                radius: ["60%", "50%"],

                center: ["50%", "50%"],
                // roseType: 'area',
                itemStyle: {
                  borderRadius: 0,
                },
                lineStyle: { color: "#FFF" },
                labelLine: {
                  show: false,
                },
                label: {
                  formatter: "{b} {c}次",
                  color: "#ffffff",
                  fontSize: "12px",
                },
                color: ["#ffb100", "#00c9af", "#00a0ff"],
                data: data,
                startAngle: 30, //旋转角度
              },
            ],
          };
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    //安全培训系统
    AQPXXTecharts() {
      let that = this;
      let data1 = [];
      let data2 = []; // 培训人数
      let data3 = []; // 总人数
      let data4 = []; //合格率
      let dom = document.getElementById("AQPXXTecharts");
      this.$echarts.init(dom).dispose();
      let myChart = this.$echarts.init(dom);
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00148_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00148_Y.length > 0) {
          res.data.data.ZB00148_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
            // data4.push((item.value2 / item.value) * 100);
            if (item.value2 == 0) {
              data4.push("0");
            } else {
              data4.push((item.value / item.value2) * 100);
            }
          });
          option = {
            legend: {
              icon: "circle",
              textStyle: {
                //fontWeight : 300 ,
                color: "#ffffff",
                fontSize: "12px", //文字的字体大小
              },
              right: "7%",
              top: "2%",
              data: ["项目总人数", "培训人数"],
              itemWidth: 10, // 设置宽度
              itemHeight: 10, // 设置高度
              itemGap: 30, // 设置间距
            },
            grid: {
              left: "3%",
              right: "2%",
              bottom: "1%",
              top: "22%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: [],
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B9C8DB", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: data2,
                name: "培训人数",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fdb047",
                    fontSize: 10,
                  },
                },
              },
              {
                barWidth: 15,
                data: data3,
                stack: "总量",
                name: "项目总人数",
                type: "bar",
                showSymbol: true,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#fcaa6e",
                        },
                        {
                          offset: 1,
                          color: "#feb51a",
                        },
                      ]
                    ),
                  },
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(213, 219, 228,0.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(213, 219, 228,0.1)",
                    },
                  ]),
                },
                z: 2,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fdb047",
                    fontSize: 10,
                  },
                },
              },
              // {
              //   name: "百分比",
              //   barWidth: 15,
              //   data: data4,
              //   stack: "总量",
              //   type: "bar",
              //   showSymbol: true,
              //   hoverAnimation: false,
              //   itemStyle: {
              //     normal: {
              //       barBorderRadius: [5, 5, 0, 0],
              //       color: "rgba(0,0,0,0)",
              //     },
              //   },
              //   label: {
              //     normal: {
              //       show: true,
              //       position: "top",
              //       formatter: function (params, trigger) {
              //         return (
              //           "合格率:" + parseInt(data4[params.dataIndex]) + "%"
              //         );
              //       },
              //       color: "#02efff",
              //       fontSize: 10,
              //     },
              //   },
              //   areaStyle: {
              //     color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         offset: 0,
              //         color: "rgba(213, 219, 228,0.6)",
              //       },
              //       {
              //         offset: 1,
              //         color: "rgba(213, 219, 228,0.1)",
              //       },
              //     ]),
              //   },
              //   z: 0,
              // },
            ],
          };

          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
              // option.series[2].data = that.baseData(option, data4, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
              // option.series[2].data = data4;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    //安全奖罚
    AQJFecharts() {
      let that = this;
      var data1 = []; //x轴数据
      var data2 = []; //奖励次数
      var data3 = []; //罚款次数
      var dom = document.getElementById("AQJFecharts");
      this.$echarts.init(dom).dispose();
      var myChart = this.$echarts.init(dom);
      let option;
      let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00149_Y`;
      visualization(params).then((res) => {
        if (res.data.data.ZB00149_Y.length > 0) {
          res.data.data.ZB00149_Y.forEach((item) => {
            data1.push(item.orgName);
            data2.push(item.value);
            data3.push(item.value2);
          });
          option = {
            legend: {
              icon: "circle",
              textStyle: {
                //fontWeight : 300 ,
                color: "#ffffff",
                fontSize: 11, //文字的字体大小
              },
              right: "7%",
              top: "2%",
              data: ["奖励次数", "罚款次数"],
              itemWidth: 10, // 设置宽度
              itemHeight: 10, // 设置高度
              itemGap: 30, // 设置间距
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "2%",
              bottom: "1%",
              top: "22%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: [],
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#B9C8DB", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#FFFFFF", //更改坐标轴文字颜色
                  fontSize: "12px", //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: [],
                name: "奖励次数",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##fcab6b",
                    fontSize: 10,
                  },
                },
              },
              {
                barWidth: 15,
                data: [],
                stack: "总量",
                name: "罚款次数",
                type: "bar",
                showSymbol: true,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#fcaa6e",
                        },
                        {
                          offset: 1,
                          color: "#feb51a",
                        },
                      ]
                    ),
                  },
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(213, 219, 228,0.6)",
                    },
                    {
                      offset: 1,
                      color: "rgba(213, 219, 228,0.1)",
                    },
                  ]),
                },
                z: 2,
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "##03e9ff",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          var Interval = null;
          clearInterval(Interval);
          InItOpen();
          Interval = setInterval(InItOpen, 3000);
          function InItOpen() {
            if (data1.length > 5) {
              option.xAxis.data = that.baseData(option, data1, 5);
              option.series[0].data = that.baseData(option, data2, 5);
              option.series[1].data = that.baseData(option, data3, 5);
            } else {
              option.xAxis.data = data1;
              option.series[0].data = data2;
              option.series[1].data = data3;
            }
            myChart.setOption(option);
          }
          window.addEventListener("resize", myChart.resize());
          option && myChart.setOption(option);
        } else {
          option = this.echartsNoData();
          option && myChart.setOption(option);
        }
      });
    },
    // 字体大小计算
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 数据处理
    baseData(option, name, size) {
      var NameData = [];
      var Data_Name = name;
      Data_Name.push(Data_Name[0]);
      Data_Name.splice(0, 1);
      for (var i = 0; i < Data_Name.length; i++) {
        NameData.push(Data_Name[i]);
        if (i + 1 == size) {
          break;
        }
      }
      return NameData;
    },
    // 无数据处理
    echartsNoData() {
      return {
        title: {
          text: "{B|}",
          left: "center",
          top: "center",
          textStyle: {
            rich: {
              B: {
                backgroundColor: {
                  image: require("../../../assets/visualization/noData.png"),
                },
                height: "100",
              },
            },
          },
        },
      };
    },
    // 是否开启滚动
    openTimer(name) {
      let that = this;
      switch (name) {
        case "XMDTsetInterval":
          if (that.XMDTlist.length <= 5) {
            return false;
          } else {
            that.xmdtToup("#XMDTsetInterval", 3000, 5);
          }
          break;
        case "AQSCsetInterval":
          if (that.AQSClist.length <= 5) {
            return false;
          } else {
            that.jumpToUp("#AQSCsetInterval", 3000, 4.6);
          }
          break;
      }
    },
    xmdtToup(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * goHeight}vh`;
        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.XMDTlist.shift();
            this.XMDTlist.push(firstChild);
            setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
    jumpToUp(dom, speed, goHeight) {
      const domElement = document.querySelector(dom);
      const everyJump = () => {
        domElement.style.transition = "margin-top 0.5s ease-in-out";
        domElement.style.marginTop = `${-1 * goHeight}vh`;

        domElement.addEventListener(
          "transitionend",
          () => {
            domElement.style.transition = "";
            domElement.style.marginTop = "0px";
            const firstChild = this.AQSClist.shift();
            this.AQSClist.push(firstChild);
            setTimeout(everyJump, speed);
          },
          { once: true }
        );
      };
      everyJump();
    },
  },
  watch: {},
};
</script>
<style lang="scss"  scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  background-color: #10121f;
  padding: 1vh;
}
body .wxSecurity {
  width: 100vw;
  height: 100vh;
  background-color: #10121f;
  position: relative;
}
.wxSecurityTitle {
  width: 100%;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
}
.wxVideoTitleItem {
  width: 100%;
  padding: 15px 4%;
  position: absolute;
  top: 90px;
  left: 0;
  z-index: 99;
}
.itemTextLiBox {
  width: 100%;
  height: 70vh;
  background-image: url(../../../assets/visualization/video/inputItem.png);
  background-size: 100% 100%;
  padding: 10px;
}
.itemTextLi {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow-y: scroll;
}
.itemText {
  width: 100%;
  padding: 10px;
  color: #e2e2e2;
  font-size: 13.997px !important;
  font-weight: 500 !important;
}
.active {
  background-color: #052f55;
}
.wxVideoTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4%;
}
.wxVideoTitleInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  height: 40px;
  background-image: url(../../../assets/visualization/video/input.png);
  background-size: 100% 100%;
  color: #e2e2e2;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Microsoft YaHei", Arial, sans-serif !important;
}
.wxVideoTitleInputIcon {
  font-size: 20px !important;
}
.wxSecurityContent {
  width: 100%;
  height: calc(100% - 110px);
  padding: 10px 2%;
  overflow: auto;
}
.wxSecurityContentItem {
  width: 100%;
  height: 280px;
  background-color: #181c28;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
.wxSecurityContentItemTitle {
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 13px;
}
.wxSecurityContentItemTitle_icon {
  height: 15px;
  padding: 3px;
  border-radius: 20%;
  background-color: #1cc0fd;
  margin-right: 10px;
}
.main {
  height: 90%;
  overflow: hidden;
}
.table-main-blueLine {
  height: 100%;
  overflow: hidden;
  padding: 0 1vh;
}
.table-main-blueLine > div > div {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #206ed4;
  height: 4vh;
  padding: 5px;
  color: #fff;
}
@font-face {
  font-family: LED;
  src: url("../../../assets/fonts/LED/Digiface-Regular.ttf");
  unicode-range: U+30-39;
}
.LED {
  font-family: LED;
  color: #3cdcff;
}
.gunDongText {
  font-size: 14px !important;
}
.gunDongImage img {
  width: 100% !important;
}
::v-deep .el-carousel__item {
  padding: 1.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.elItem {
  width: 100%;
  display: flex;
  height: 47%;
  justify-content: space-between;
}
.elItemImageBox {
  width: 40%;
}
.elItemImageBox img {
  width: 100%;
  height: 100%;
}
.LBtxt {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dian2 {
  display: inline-block;
  width: 100%;
  // white-space: nowrap; /* 禁止换行 */
  // overflow: hidden; /* 超出部分隐藏 */
  // text-overflow: ellipsis; /* 显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.dian1 {
  display: inline-block;
  width: 100%;
  // white-space: nowrap; /* 禁止换行 */
  // overflow: hidden; /* 超出部分隐藏 */
  // text-overflow: ellipsis; /* 显示省略号 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.Nodataavailableatthemoment {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.Nodataavailableatthemoment img {
  height: 100px;
}
.pojectNews {
  width: 100%;
  height: 100%;
}
.pojectNews > div {
  background-image: url(../../../assets/visualization/securityManagement/sanjiao.png);
  background-size: 100% 100%;
  margin-bottom: 1.5vh;
  display: flex;
  padding: 1vh;
  align-items: center;
}
</style>
